import styled from 'styled-components'

import { salmon, lightGray } from '../../../../styles/themeColors'

export const PreviewContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding-bottom: 1.5rem;
  position: relative;
  & .window {
    background-color: white;
    position: relative;
    will-change: transform;
    width: 100%;
    & iframe {
      background-color: white;
      border: none;
      height: 100%;
      min-height: 100%;
      min-width: 100%;
      width: 100%;
    }
    &.desktop {
      border-radius: 5px;
      height: 740px;
      transform: scale(0.62);
      padding: 30px 0 0 0;
      width: 1300px;
    }
    &.mobile {
      border-radius: 5px;
      height: 736px;
      transform: scale(1);
      padding: 15px 0 20px 3px;
      width: 414px;
    }
    & .dots {
      height: 10px;
      position: absolute;
      right: 0px;
      top: 5px;
      width: 75px;
      & span {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        display: inline-block;
        height: 15px;
        margin: 0 3px;
        width: 15px;
      }
    }
  }
  @media (max-width: 1500px) {
    & .window {
      &.desktop {
        margin-top: -45px;
        transform: scale(0.5);
      }
    }
  }
  @media (max-width: 700px) {
    & .window {
      &.desktop {
        margin-top: -80px;
        transform: scale(0.4);
      }
    }
  }
  @media (max-width: 560px) {
    padding-bottom: 0;
    & .window {
      &.desktop {
        margin-top: -120px;
        transform: scale(0.27);
      }
    }
  }
  @media (max-width: 450px) {
    & .window {
      &.mobile {
        margin-top: -75px;
        transform: scale(0.8);
      }
    }
  }
`

export const PreviewControls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  & svg {
    margin: 0 10px;
    color: ${lightGray};
    &.active {
      color: ${salmon};
    }
  }
`

export const EventPicture = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & .event-picture-landing {
    height: auto;
    max-width: 326px;
  }
`

export const InvitationPreviewContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  & .mail-body {
    align-items: center;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 94%;
    & .logo {
      display: block;
      width: 260px;
    }
  }
`
