import React from 'react'
import get from 'lodash/fp/get'
import find from 'lodash/fp/find'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { RSVP_PREVIEW_DATA } from '../../graphql'
import { SimpleLoader } from '../../components'
import { getCheckInOut, replaceLineBreaksWithBR } from '../../common'
import { Common404 } from '../../components/Error404'
import { MyEvent, EventComponent, EventComponentStatus } from '../../types'
import user from '../../assets/images/rsvp/user.jpg'
import Arrow from '../../assets/images/icons/arrow.svg'

import { RSVPcontainer } from './style'
import { RSVPtitle } from './components/style'
import { EventSegments, RSVPmap, defaultIntro } from './components'
import { getBgImage, exampleData } from './RSVPpreviewExampleData'

export const RSVPpreview: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const variables = { id: eventId }
  const { data, loading } = useQuery(RSVP_PREVIEW_DATA, { variables })
  const event: MyEvent = get('me.myEvents[0]', data)

  if (loading) return <SimpleLoader />
  else if (!data) {
    return (
      <Common404 text="No preview data" link={`/event/${eventId}/settings`} />
    )
  }

  const { Available } = EventComponentStatus
  const paidComponents = event.components.filter(el => el.status === Available)
  const mainComponent =
    find({ main: true }, paidComponents) || paidComponents[0]
  const mainProvider = mainComponent?.offering.provider

  const bgImage = getBgImage(mainProvider?.mainImage)
  const { description, mainImage } = event
  return (
    <RSVPcontainer className="pt-0 pt-sm-4 container">
      <div className="rsvp-bg d-none d-sm-inline-block">
        <img src={bgImage} alt="Hacienda Temozon" />
      </div>
      <RSVPtitle
        className="font-lf"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        {event.name}
      </RSVPtitle>
      <Card
        body
        style={{ maxWidth: '700px', zIndex: 10 }}
        className="mx-auto shadow-sm pb-2 text-center"
      >
        <div>
          <p className="fz-24 m-0 text-primary fwb">You’re Invited!</p>
          <img src={mainImage || user} alt="User" className="mw-landing-img" />
          <div className="d-block text-center tc-gray fz-20 fwsb mt-1 no-under">
            {mainProvider?.name || 'Hacienda Temozon'}
            <p
              style={{ maxWidth: '320px' }}
              className="fz-11 tc-lgray fwn mx-auto mt-0 mb-1 fwn"
            >
              {mainProvider?.address}
            </p>
            <span className="text-primary fz-13 d-block">
              {mainProvider?.location || 'Merida, México'}
            </span>
            {mainComponent?.date
              ? getCheckInOut({
                  date: mainComponent.date,
                  dateFormat: 'MMM DD, YYYY',
                  duration: mainComponent.duration,
                })
              : 'April 14th, 2023'}
          </div>
        </div>
        <p className="fz-24 pt-4 fwb">Will you join us?</p>
        <p className="pt-1 px-0 px-sm-5">
          Hey {'{GUEST}'}! <br />
          {description ? replaceLineBreaksWithBR(description) : defaultIntro()}
        </p>
        <Button className="rounded-pill">Join Event</Button>
        <h6
          className="mx-auto mt-4 position-relative fwsb"
          style={{ maxWidth: '350px' }}
        >
          Book your room, get updates
          <br />& chat with the group
          <img
            src={Arrow}
            alt="->"
            className="arrow-rsvp h-auto position-absolute"
            style={{
              width: '40px',
              transform: 'scaleX(-1) rotate(-40deg)',
              top: '-28px',
              right: '65px',
            }}
          />
        </h6>
      </Card>

      {/** if there are paid components the default components are replaced by the paid components */}
      <EventSegments
        eventId={eventId}
        eventName={event.name}
        guest={{
          firstName: 'firstName',
          lastName: 'lastName',
          email: 'email@gmail.com',
          status: 'accepted',
        }}
        eventComponents={
          paidComponents.length > 0
            ? paidComponents
            : (exampleData.eventComponents as EventComponent[])
        }
      />
      {/** just to remove the Maybe type */}
      <RSVPmap
        components={
          paidComponents.length > 0
            ? paidComponents
            : (exampleData.eventComponents as EventComponent[])
        }
      />
    </RSVPcontainer>
  )
}
