import React, { Suspense, lazy } from 'react'
import find from 'lodash/fp/find'
import Button from 'react-bootstrap/Button'
import { Helmet } from 'react-helmet'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import Arrow from '../../assets/images/icons/arrow.svg'
import { Feed } from '../SocialPanel'
import {
  saveSelectedEvent,
  useCurrentUser,
  useUpdateInvitation,
} from '../../common'
import { SimpleLoader } from '../../components'
import { GET_INVITATION_DATA } from '../../graphql'
import { Common404 } from '../../components/Error404'
import { guestStatuses, myHub } from '../../common/constants'
import {
  InvitationInfo,
  EventComponent,
  GuestEvent,
  Invitation,
  EventComponentStatus,
} from '../../types'

import { RSVPcontainer } from './style'

const InvitationBody = lazy(() => import('./components/InvitationBody'))

export const InternalUseInvitation: React.FC = () => {
  const history = useHistory()
  const { firstName, lastName, email } = useCurrentUser()
  const eventId = useParams<{ id: string }>().id

  const { data, loading } = useQuery(GET_INVITATION_DATA, {
    variables: { eventId },
  })
  const { updateInvitation, updating } = useUpdateInvitation({ alerts: true })

  if (loading) return <SimpleLoader />
  if (!data && !loading) return <Common404 text="Data not available" />

  const invitation: InvitationInfo = data?.me?.invitation
  const components: EventComponent[] = invitation?.components || []
  const { Available } = EventComponentStatus
  const eventComponents: EventComponent[] = components.filter(
    el => el.status === Available
  )
  // if there are no components with AVAILABLE status, means that the event is still in negotiation
  if (eventComponents.length <= 0) return <Common404 text="Location TBD" />

  const mainComponent =
    find({ main: true }, eventComponents) || eventComponents[0]
  const { date, duration } = mainComponent
  const { name, description, mainImage } = invitation

  const { accepted } = guestStatuses
  const eventGuest: Invitation = find({ eventId }, data?.me?.invitations)
  const handleRSVP = (event: GuestEvent) => {
    if (updating) return
    updateInvitation({ event, guestId: eventGuest?.guestId, eventId })
  }

  const isAccepted = eventGuest?.status === accepted
  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (isAccepted) {
      saveSelectedEvent(eventId)
      history.push(myHub)
    } else {
      handleRSVP(GuestEvent.Accept)
    }
  }
  return (
    <RSVPcontainer className="pt-0 pt-sm-4 container">
      <Helmet>
        <title>{name} - Kiss & Tell</title>
        <meta
          name="description"
          content={`You're invited to ${name}. Check out the details and RSVP to the event.`}
        />
      </Helmet>
      <Suspense fallback={<SimpleLoader />}>
        <InvitationBody
          firstName={firstName}
          mainProvider={mainComponent.offering.provider}
          eventComponents={eventComponents}
          event={{
            id: eventId,
            date,
            duration,
            name,
            description,
            mainImage,
          }}
          guest={{
            firstName,
            lastName,
            email,
            status: eventGuest?.status,
          }}
          kidsAllowed={eventGuest?.kidsAllowed}
          plusOne={eventGuest?.plusOne}
        >
          <>
            <Button className="rounded-pill" onClick={handleClick}>
              {isAccepted ? 'Go To Event' : 'Join Event'}
            </Button>
            <h6
              className="mx-auto mt-4 position-relative fwsb"
              style={{ maxWidth: '350px' }}
            >
              Book your room, get updates
              <br />& chat with the group
              <img
                src={Arrow}
                alt="->"
                className="arrow-rsvp h-auto position-absolute"
                style={{
                  width: '40px',
                  transform: 'scaleX(-1) rotate(-45deg)',
                  top: '-28px',
                  right: '65px',
                }}
              />
            </h6>
            {isAccepted && (
              <span
                className="fz-12 tc-lgray d-block pt-3 pt-0 cursor under"
                onClick={() => handleRSVP(GuestEvent.Decline)}
              >
                Remove this event from my calendar
              </span>
            )}
          </>
        </InvitationBody>
      </Suspense>
      <h3 className="fz-30 mb-4 mt-5 text-center">Conversation</h3>
      <Feed eventId={eventId} myEvents={[]} noGuests={false} />
    </RSVPcontainer>
  )
}
