import React from 'react'
import get from 'lodash/fp/get'
import groupBy from 'lodash/fp/groupBy'
import values from 'lodash/fp/values'
import flow from 'lodash/fp/flow'

import { SimpleTooltip } from '../../../components'
import { EventComponent, Provider } from '../../../types'

import { EventComponentsSlider } from '.'

interface IComponents {
  isMyEvent: boolean
  components: EventComponent[]
}

export const Components: React.FC<IComponents> = ({
  components,
  isMyEvent,
}) => {
  const groupedComponents = flow(
    groupBy('offering.provider.id'),
    values
  )(components)

  return (
    <>
      {groupedComponents.map(el => {
        const provider: Provider = get('[0].offering.provider', el)

        return (
          <React.Fragment key={`rsvp-by-${provider.id}`}>
            <div className="mt-4">
              <span className="d-block text-primary mt-3 fz-14 position-relative w-fit-content mx-auto px-3">
                {provider.name}
                {provider.address && (
                  <SimpleTooltip
                    id={`${provider.id}-provider-address`}
                    className="tooltip-card top-right"
                    text={provider.address}
                  />
                )}
              </span>
              {provider.location}
            </div>
            <EventComponentsSlider eventComponents={el} isMyEvent={isMyEvent} />
          </React.Fragment>
        )
      })}
    </>
  )
}
