import React, { useState } from 'react'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import flatten from 'lodash/fp/flatten'

import { FullSlider } from '../../../components'
import { Image, Maybe, PackageAddons } from '../../../types'
import { formatTime, getImages } from '../../../common'
import { LocationOverviewContainer } from '../../../components/Cards/style'

export const PackageAddonsImages: React.FC<{
  customizations: Maybe<PackageAddons[]>
}> = ({ customizations }) => {
  const [current, setCurrent] = useState(0)
  if (customizations && customizations.length <= 0) return <></>

  const images: Image[] = flow(
    map(get('offeringAddOn.images')),
    flatten
  )(customizations)
  const imagesArray = getImages(images, '800x450')

  const metadata = find(
    { offeringAddOn: { id: images[current]?.assocId } },
    customizations
  ) as PackageAddons

  return (
    <div className="position-relative" style={{ maxWidth: '800px' }}>
      {imagesArray.length > 1 && (
        <FullSlider small images={imagesArray} afterChange={setCurrent} />
      )}
      {images.length === 1 && (
        <img src={imagesArray[0]} alt="Package Addon" className="img-fluid" />
      )}

      {metadata && (
        <div className="location-overview-container">
          <LocationOverviewContainer className="panel-style packages">
            <h4 className="pl-1 m-0 mt-2">{metadata.offeringAddOn.label}</h4>
            <div className="LocationOverview">
              <div>
                <span className="name fz-16">Location</span>
                <span>{metadata.location}</span>
              </div>

              <div>
                <span className="name fz-16">Time</span>
                <span>{formatTime(metadata.time)}</span>
              </div>

              <div>
                <span className="name fz-16">Duration</span>
                <span>{metadata.offeringAddOnOption.label}</span>
              </div>
            </div>
          </LocationOverviewContainer>
        </div>
      )}
    </div>
  )
}
