import React, { useEffect } from 'react'
import get from 'lodash/fp/get'
import sum from 'lodash/fp/sum'
import sumBy from 'lodash/fp/sumBy'
import toNumber from 'lodash/fp/toNumber'
import round from 'lodash/round'

import { FormatNumber, SimpleTooltip } from '../../../components'
import { setTotalPrice } from '../../../features/bookingSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  Booking,
  Offering,
  IcurrentAddons,
  OfferingAddOnOptions,
} from '../../../types'

import {
  extraGuests,
  extraAddons,
  extraFreeGuests,
  useCalculateBookingTotals,
} from './bookingFunctions'

const selectedExtraNights = (
  nights: OfferingAddOnOptions[],
  offering: Offering
): JSX.Element => (
  <>
    {nights.map(el => (
      <tr className="fz-13" key={`breakdown-row-${el.id}`}>
        <td>
          <span className="fz-12 pl-2">
            {`${el.label}`}
            {!el?.price && (
              <SimpleTooltip
                id={`${el.id}-extra-night-desc`}
                className="tooltip-card"
                text={`$${round(offering.price, 2)} /nightly rate`}
              />
            )}
          </span>
        </td>
        <td className="pl-3">
          <FormatNumber
            n={el.price || round(el.quantity * toNumber(offering.price), 2)}
          />
        </td>
      </tr>
    ))}
  </>
)

export const RoomBookingBreakdown: React.FC<{
  currentAddons: IcurrentAddons
  booking: Booking
}> = ({ currentAddons, booking }) => {
  const dispatch = useAppDispatch()
  const {
    freeGuests = [],
    paidGuests = [],
    extraNights = [],
    customBookingDuration,
    availableAddons: { extraGuestAddon },
  } = useAppSelector(state => state.booking)

  const { offering } = booking.eventComponent
  const duration = sum([
    customBookingDuration,
    -1,
    extraNights[0]?.quantity || 0, // we can only have 2 possible extra nights, after or before
    extraNights[1]?.quantity || 0,
  ])
  const { basePrice, total, tax, offeringPrice } = useCalculateBookingTotals(
    booking,
    duration,
    currentAddons
  )

  const { noTaxables, taxables } = currentAddons

  const addedAdults = sumBy(el => {
    if (el.type === 'adult') return 1
    return 0
  }, paidGuests)

  useEffect(() => {
    dispatch(setTotalPrice(total))
  }, [dispatch, total])

  return (
    <div className="mb-3">
      <table data-cy="breakdown">
        <tbody>
          <tr className="fz-15">
            <td data-cy="book-duration">
              <FormatNumber n={total / duration} /> x {duration} nights:
            </td>
            <td className="pl-3" data-cy="total-room">
              <FormatNumber n={total} />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="fz-13 py-1">
              Rate includes {sum([get('room.basic', offering), addedAdults])}{' '}
              adult(s)
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr className="my-2" />
            </td>
          </tr>
          <tr className="fz-13">
            <td>Base Price:</td>
            <td className="pl-3">
              <FormatNumber n={basePrice} />
            </td>
          </tr>
          <tr className="fz-13">
            <td>
              <span className="fz-12 pl-2">Room price</span>
            </td>
            <td className="pl-3">
              <FormatNumber n={offeringPrice} />
            </td>
          </tr>

          {freeGuests.map(el => extraFreeGuests(el))}
          {paidGuests.map(el => extraGuests(el, extraGuestAddon))}

          {selectedExtraNights(extraNights, offering)}
          <tr>
            <td className="aux py-1" colSpan={2} />
          </tr>

          {taxables.map(el => extraAddons(el, basePrice))}
          <tr className="fz-13">
            <td>Tax:</td>
            <td className="pl-3">
              <FormatNumber n={tax} />
            </td>
          </tr>
          <tr>
            <td className="aux py-1" colSpan={2} />
          </tr>
          {noTaxables.map(el => extraAddons(el, basePrice))}
        </tbody>
      </table>
    </div>
  )
}
