import { SetStateAction } from 'react'
import { isValidNumber } from 'libphonenumber-js'

interface IvaildatePhone {
  phone?: string
  setPhoneError?: (value: SetStateAction<string>) => void
  requiered?: boolean
}
export const useValidatePhone = (): {
  validatePhone: ({
    phone,
    setPhoneError,
    requiered,
  }: IvaildatePhone) => boolean
} => {
  const validatePhone = ({
    phone,
    setPhoneError,
    requiered = true,
  }: IvaildatePhone) => {
    if (!phone) {
      setPhoneError?.(requiered ? 'Phone number is required' : '')
      return !requiered
    }

    if (!isValidNumber(phone)) {
      setPhoneError?.('Invalid phone number')
      return false
    }

    setPhoneError?.('')
    return true
  }
  return { validatePhone }
}
