import React from 'react'
import flow from 'lodash/fp/flow'
import trim from 'lodash/fp/trim'
import toLower from 'lodash/fp/toLower'
import split from 'lodash/fp/split'
import kebabCase from 'lodash/fp/kebabCase'

import { Maybe } from '../types'

export const replaceLineBreaksWithBR = (text: string): JSX.Element[] => {
  const splitText = split(/\r\n|\n|\r/gm, text)

  return splitText.map((el, idx) => (
    <React.Fragment key={kebabCase(el)}>
      {idx > 0 && <br />}
      {el}
    </React.Fragment>
  ))
}

export const shortenLink = (match: string): string => {
  const shortLink = match // pending service

  return `<a href="${match}" target="_blank" rel="noopener noreferrer">${shortLink}</a>`
}

// we cant use this functions with Maybe<string> so we need to cast it to string
export const cleanString = (text: Maybe<string> = ''): string =>
  flow(trim, toLower)(text as string)
